import React, { useState } from "react";
import Join from "./Join";
import Login from "./Login";
function App() {
  const [page, setPage] = useState("home")//Defining the Default State-home page
  function handelLogin(e){//To change default state to login
    e.preventDefault()
    setPage("login")
  }
  function handelJoin(e){//To change default state to join
    e.preventDefault()
    setPage("join")
  }
  return (
    <div className="container">
      <div className="form">
      {page === "home" && <div className="form">{/* Homepage containing two buttons, login and join*/}
      <p style = {{color : "black"}}>Already Registered? Login here.</p>
        <button className="btn btn-primary" type = "submit" onClick = {handelLogin}>Login</button>{/* Login button*/}
        <br/>
        <br/>
        <p style = {{color : "black"}}>New User? Register here</p>        
        <button className="btn btn-primary" type = "submit" onClick = {handelJoin}>Join</button>{/* Join button*/}
      </div>}
      
      {page === "login" && <Login loginWelcome = "Please, Enter your details here"/>}{/*On click It takes to the respective page*/}
      {page === "join" && <Join/>}
      </div>
    </div>
  );
}

export default App;
