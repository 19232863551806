import React, {useState} from 'react';
function FileUploadPage(props){ // To handle Upload

	const [selectedFile, setSelectedFile] = useState();//To store File
	const [isFilePicked, setIsFilePicked] = useState(false);//To know wheather file is selected
	//const [stateUploaded , setStateUploaded ] = useState(false)
	const formData = new FormData();//For calling api to validate file
	const [buttonName,setButtonName] = useState("Submit")//To change name of button in diffrent stages
	const [resultText,setResultText] = useState("")//Final Message
	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	async function uploadFile(){//To upload file in azure blob
		// setStateBool(false)
		//console.log("m chl gya")
		formData.append("file", selectedFile);
		formData.append("company_name", props.company);
		formData.append("tEmail", props.email);
		console.log(selectedFile.name)
        for (const pair of formData.entries()) {
			console.log(`${pair[0]}, ${pair[1]}`);
		  }		// To console log form data  
		console.log(props.email)
		console.log(props.company)


		// setButtonName(<div>
		// <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
		//  Validating...</div>)
		await fetch(
		'https://kovaionapi.azurewebsites.net/api/azureblob',
		{
			method: 'POST',
			// body : '{"url" : "https://file-examples.com/wp-content/uploads/2017/02/file_example_XLSX_5000.xlsx"}'
			body: formData,
		}
	)

		.then(response => response.text())
		.then(result => {setResultText("Mailed Successfully")
		setButtonName("Upload")})
		.catch(error => console.log('error', error));
		//setStateUploaded(true)
	}

	const handleSubmission = (event) => {// Executed when Upload button is clicked
		event.preventDefault()
		// setResultText("")
		setResultText("File has been successfully uploaded. You will receive further updates shortly via email")
		isFilePicked&&uploadFile()
		!isFilePicked&&emptyFile()
	};

	function emptyFile(){
		alert("Please choose a file")
	}

	

	return(
   <div>
			<input style={{paddingLeft : "50px"}} type="file" name="file" onChange={changeHandler} />
            <br/>
            <br/>
			<div>
			<button type = "button" className="btn btn-primary" onClick={handleSubmission}>{buttonName}</button>
			</div>
			<div>
				<p style={{color : "black"}}>{resultText}</p>
			</div>
		</div>
	)
}
export default FileUploadPage