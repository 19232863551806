import React,{useState} from "react";
//import FileUploadPage from "./FileUploadPage";
import ExcelPage from "./ExcelPage";

function Login(props) {
  const [sentOtp,setSentOtp] = useState("00000070000089")//Initial value of otp untill any other new otp is generated
  const [otpAlert,setOtpAlert] = useState("")//
  const [flagLogin , setFlagLogin] = useState(true)
  //const [loginWelcome,setLoginWelcome] = useState("Please, insert login credentials")
  const [flagGeneratingOtp,setFlagGeneratingOtp] = useState(false)//TRue when OTP is being generated
  const [loginDetails, setLoginDetails] = useState(// COntains form data of website
    {tcompany : "",
    temail : "",
    totp : ""}
  )
  const [newData , setNewData] = useState({// Will be used to call API getLogindetails to check wheather 
    tname: "CHECK",
    tcompany: "CHECK",
    temail: ""
  });

  //(props.userExists === "Yes") && setLoginWelcome("Your details already exists please try loging in")

  function handelChange(event){// To update the form values as user make changes
    const {name, value}= event.target;

    setLoginDetails(prevValue => {
      return {
        ...prevValue,
        [name] : value
        };
      });

      if (name === "temail") {
        setNewData(prevValue => {
          return {
            ...prevValue,
            [name] : value
            };
          })
      }   

    };

  function handelClick(event){//Will be executed when lOGIN button is clicked
    event.preventDefault()
    if (sentOtp.toString() === loginDetails.totp){setFlagLogin(false)}
    else{
      alert("Enter a valid OTP")
    }
  }

  function handelGenerateClick(event){// To Generate OTP when clicked
    event.preventDefault()
    //generateOtp()


    //For checking User in existing Data Base Entering Data in existing data base
     
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    console.log(newData)

    var raw = JSON.stringify(newData);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    async function updateDataBase(){// Trying to update database to check whether Email ID exists or not in the DataBase
        await fetch("https://kovaionapi.azurewebsites.net/api/getlogindetails", requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result === "Failed to insert data exception: Data already exists"){
            generateOtp()
            setOtpAlert("User Mail ID exists in database Generating OTP")
          }
          if(result === "Data inserted successfully"){
            setOtpAlert("Data doesn't Exists try to join as a new user")
          }
          })
        .catch(error => console.log('error', error));

  }

  updateDataBase()
  }

  function generateOtp(){// To generate OTP when Email ID is already verified
    //##########
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    var raw = "{\"tEmail\":\""+ loginDetails.temail +"\"}";
    setFlagGeneratingOtp(true)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://kovaionapi.azurewebsites.net/api/otp", requestOptions)
      .then(response => response.text())
      .then(result => {setSentOtp(result)
        //console.log(result)
      })
      .catch(error => console.log('error', error));
      setFlagGeneratingOtp(false)

    //##########
}


  return (
    <div>
      {flagLogin?<div>
      <h1>{props.loginWelcome}</h1>
      <form className="form" style = {{"paddingBottom" : "0"}}>
        <input name = "tcompany" type="text" placeholder="Company" value = {loginDetails.tcompany} onChange = {handelChange}/>
        <input name = "temail" type="email" placeholder="Email Id" value = {loginDetails.temail} onChange = {handelChange}/>
        <button type="submit" style = {{"marginBottom" : "5px","marginTop" : "5px"}} onClick = {handelGenerateClick}>Generate OTP</button>
        {/* <p style = {{"color" : "#ccd8de"}}>....</p> */}
        <p style = {{color : "black" , "marginBottom" : "0"}}>{otpAlert}</p>
        {flagGeneratingOtp && <p style={{color : "black"}}>Generating OTP...</p>}
        <input name = "totp" type="text" placeholder="Enter OTP received on Mail"  value = {loginDetails.totp} onChange = {handelChange} />
        
        <button type="submit" style = {{"marginTop" : "0px"}} onClick = {handelClick}>Login</button>

      </form></div>:<ExcelPage company = {loginDetails.tcompany} email = {loginDetails.temail}/>}

    </div>
  );
}

export default Login;