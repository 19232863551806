import React from 'react'
import FileUploadPage from './FileUploadPage'
function ExcelPage(props) {// Page to show Download and Upload FIle option 
  return (
    <div className='form' style={{color : "black" , zIndex : "+1", marginLeft : "10%", marginRight : "10%"}}>
        <div>
            <p><h2>Please click on the link below to download the sample excel sheet</h2></p>
        <p>
          <br/>
        <h3><a href="https://kovaion-my.sharepoint.com/:u:/p/sudipta_hazra/EZvBy3OkNeNGv_9ai5H85hwB9dhNQsgpTlonXNR965P3Sw?e=yLL93F" target="_blank" rel="noreferrer noopener">DOWNLOAD</a></h3> {/*Sharepoint Link*/}
        </p>
        </div>

    <div>
      <p>
        <br/>
         <FileUploadPage name = {props.name} email = {props.email} company = {props.company}/> {/*File Upload Page to show Upload Option */}
      </p>
    </div>
    </div>
  )
}

export default ExcelPage